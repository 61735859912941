<template>
  <v-footer class="site-footer grey lighten-3 grey--text text--darken-4 py-8">
    <v-container>      
      <v-row class="py-4 my-4" no-gutters v-if="$route.name !== 'signUp' && $route.name !== 'availability' && $route.name !== 'contest'">
        <v-col cols="12" class="text-center text-h4 font-weight-black">
          Sign up or call TODAY!
        </v-col>
        <v-col cols="12" class="text-center text-h4 primary--text font-weight-black">
          276-935-8307
        </v-col>
        <v-col cols="12" class="text-center text-subtitle-1 font-weight-light">
          We take you where you want to go!
        </v-col>
        <v-col cols="12" class="text-center pt-4 mt-4">
          <v-btn x-large class="py-4" color="primary" to="/sign-up">Sign Up Today!</v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="text-center text-caption">
          1095 Plaza Drive, Grundy VA 24614
        </v-col>
        <v-col cols="12" class="text-center text-caption">
          &copy; <strong> {{ new Date().getFullYear() }} iGo Technology </strong> | <strong> All rights reserved. </strong> 
        </v-col>
        <v-col cols="12" class="text-center text-caption">
          <router-link to="/policies">Policies &amp; Legal</router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.site-footer {
  box-shadow: inset 0px 12px 18px -4px rgba(0,0,0,0.15) !important;
}
</style>
