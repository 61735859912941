import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueGtag from "vue-gtag"

Vue.use(Vuetify);
Vue.use(VueGtag, {
  config: { id: 'G-3CK5TE2JVJ' }
})
export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {    
        primary: "#25548c",
        secondary: "#76cae9",
        accent: "#24678d",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      },
      dark: {    
        primary: "#25548c",        
        secondary: "#76cae9",
        accent: "#24678d",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }      
    }    
  }
});
