import Vue from 'vue'
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter

Vue.use(VueRouter)

const appName = 'iGo Technology'

const router = new VueRouter({  
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [            
    {
      path: '/',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        title: 'Home'
      }
    },  
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        title: 'Home'
      }
    },
    {
      path: '/availability',
      name: 'availability',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Availability.vue'),
      meta: {
        title: 'Availability & Sign Up'
      }
    },
    {
      path: '/sign-up',
      name: 'signUp',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Availability.vue'),
      meta: {
        title: 'Availability & Sign Up'
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "support" */ '@/views/Contact.vue'),
      meta: {
        title: 'Contact'
      }
    },          
    {
      path: '/careers',
      name: 'careers',
      component: () => import(/* webpackChunkName: "support" */ '@/views/Careers.vue'),
      meta: {
        title: 'Careers'
      }
    },               
//    {
//      path: '/sweepstakes',
//      name: 'sweepstakes',
//      component: () => import(/* webpackChunkName: "home" */ '@/views/Sweepstakes.vue'),
//      meta: {
//        title: 'Sweepstakes'
//      }
//    },
    {
      path: '/policies',
      name: 'policies',
      component: () => import(/* webpackChunkName: "support" */ '@/views/Policies.vue'),
      meta: {
        title: 'Policies'
      }
    },
    {
      path: '/services',
      name: 'services',
      component: () => import(/* webpackChunkName: "info" */ '@/views/Services.vue'),
      meta: {
        title: 'Services'
      }
    },
    {
      path: '/support',
      name: 'support',
      component: () => import(/* webpackChunkName: "support" */ '@/views/Support.vue'),
      meta: {
        title: 'Support'
      }
    },
    {
      path: '/notfound',
      name: 'notFound',
      component: () => import(/* webpackChunkName: "support" */ '@/views/NotFound.vue'),
      meta: {
        title: 'Page Not Found'
      }
    }  
  ], 
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (!to.matched.length)
    next(`/notfound`)
  else 
    next()  
})

router.afterEach((to) => {
  Vue.nextTick(() => document.title = to.meta.title ? to.meta.title + ` - ${appName}` : appName)
})

export function handleRouterError(error) {
  if (!isNavigationFailure(error, NavigationFailureType.aborted)) {
    throw error
  }
}

export default router
