<template>
  <v-app>
    <SiteHeader />
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
    <SiteFooter />
  </v-app>
</template>
<script>
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter
  }
}
</script>

